import Layout from '@/components/layouts/Layout'
import { ContainerNarrow } from '@/components/styled/Container'
import StyledLink from '@/components/styled/Link'
import { H1, H3, LongFormContent } from '@/components/styled/Typography'
import React from 'react'
import { Helmet } from 'react-helmet'

export default function PrivacyPolicy(): JSX.Element {
  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <Layout LayoutContainer={ContainerNarrow}>
        <head>
          <meta name="robots" content="noindex" />
        </head>
        <LongFormContent>
          <H1>Privacy Policy</H1>

          <H3>What information do we collect?</H3>
          <p>
            We collect information from you when you register on our site, place
            an order, subscribe to our newsletter, respond to a survey or fill
            out a form. When ordering or registering on our site, as
            appropriate, you may be asked to enter your: name, e-mail address,
            mailing address, phone number or credit card information. You may,
            however, visit our site anonymously.
          </p>
          <H3>What do we use your information for?</H3>
          <p>
            Any of the information we collect from you may be used in one of the
            following ways:
          </p>
          <ul>
            <li>
              To personalize your experience. Your information helps us to
              better respond to your individual needs.
            </li>
            <li>
              To improve our website. We continually strive to improve our
              website offerings based on the information and feedback we receive
              from you.
            </li>
            <li>
              To improve customer service. Your information helps us to more
              effectively respond to your customer service requests and support
              needs.
            </li>
            <li>To process transactions.</li>
            <li>
              Your information, whether public or private, will not be sold,
              exchanged, transferred, or given to any other company for any
              reason whatsoever, without your consent, other than for the
              express purpose of delivering the purchased product or service
              requested.
            </li>
            <li>
              To administer a contest, promotion, survey or other site features.
            </li>
            <li>To send periodic emails.</li>
            <li>
              The email address you provide for order processing, may be used to
              send you information and updates pertaining to your order, in
              addition to receiving occasional company news, updates, related
              product or service information, etc.
            </li>
          </ul>
          <p>&nbsp;</p>
          <H3>How do we protect your information?</H3>
          <p>
            We implement a variety of security measures to maintain the safety
            of your personal information when you place an order or enter,
            submit, or access your personal information. We offer the use of a
            secure server. All supplied sensitive/credit information is
            transmitted via Secure Socket Layer (SSL) technology and then
            encrypted into our Payment gateway providers database only to be
            accessible by those authorized with special access rights to such
            systems, and are required to keep the information confidential.
            After a transaction, your private information (credit cards, social
            security numbers, financials, etc.) will not be stored on our
            servers.
          </p>
          <H3>Do we use cookies?</H3>
          <p>
            Yes. Cookies are small files that a site or its service provider
            transfers to your computers hard drive through your Web browser (if
            you allow) that enables the sites or service providers systems to
            recognize your browser and capture and remember certain information.
            We use cookies to help us remember and process the items in your
            shopping cart, understand and save your preferences for future
            visits and compile aggregate data about site traffic and site
            interaction so that we can offer better site experiences and tools
            in the future. We may contract with third-party service providers to
            assist us in better understanding our site visitors. These service
            providers are not permitted to use the information collected on our
            behalf except to help us conduct and improve our business.
          </p>
          <H3>Do we disclose any information to outside parties?</H3>
          <p>
            We do not sell, trade, or otherwise transfer to outside parties your
            personally identifiable information. This does not include trusted
            third parties who assist us in operating our website, conducting our
            business, or servicing you, so long as those parties agree to keep
            this information confidential. We may also release your information
            when we believe release is appropriate to comply with the law,
            enforce our site policies, or protect ours or others rights,
            property, or safety. However, non-personally identifiable visitor
            information may be provided to other parties for marketing,
            advertising, or other uses.
          </p>
          <H3>Third party links</H3>
          <p>
            Occasionally, at our discretion, we may include or offer third party
            products or services on our website. These third party sites have
            separate and independent privacy policies. We therefore have no
            responsibility or liability for the content and activities of these
            linked sites. Nonetheless, we seek to protect the integrity of our
            site and welcome any feedback about these sites.
          </p>
          <H3>Your rights with respect to your personal information</H3>
          <ul>
            <li>
              <strong>
                Right to request deletion of the data - Request to delete
              </strong>{' '}
              <br /> You can request to delete your personal information. We
              will respect your request and delete your personal information,
              subject to certain exceptions provided by law.
            </li>
            <li>
              <strong>Other rights</strong> <br /> Dependent on local
              regulations such as GDPR chapter 3 and CCPA, your rights include:
              <ul>
                <li>The Right to Information</li>
                <li>The Right of Access</li>
                <li>The Right to Rectification</li>
                <li>The Right to Erasure</li>
                <li>The Right to Restriction of Processing</li>
                <li>The Right to Data Portability</li>
                <li>The Right to Object</li>
                <li>The Right to Avoid Automated Decision-Making</li>
              </ul>
            </li>
          </ul>
          <p>
            To make a request for deletion of your data, submit a request for
            information or otherwise exercise these rights, you can contact us
            from this contact form:&nbsp;
            <StyledLink to="/contact">https://ichigo.com/contact</StyledLink>.
          </p>
          <H3>Changes to our Privacy Policy</H3>
          <p>
            If we decide to change our privacy policy, we will post those
            changes on this page.
          </p>
          <H3>Contacting Us</H3>
          <p>
            If there are any questions regarding this privacy policy you may
            contact us using the information below.
          </p>
          <p>
            ICHIGO Inc.
            <br />
            <StyledLink to="/contact">Contact Us</StyledLink>
          </p>
        </LongFormContent>
      </Layout>
    </>
  )
}
